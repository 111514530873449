import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const env = createEnv({
  server: {
    NEXT_PREVIEW_TOKEN: z.string(),
    CENTRA_API_SECRET: z.string(),
    CENTRA_INTEGRATION_TOKEN: z.string(),
    CENTRA_INTEGRATION_URL: z.string().url(),
    STORYBLOK_ACCESS_TOKEN: z.string(),
    STORYBLOK_ACCESS_TOKEN_PREVIEW: z.string(),
    CENTRA_DOMAIN: z.string(),
  },
  client: {
    NEXT_PUBLIC_STORYBLOK_TOKEN: z.string(),
    NEXT_PUBLIC_CENTRA_CHECKOUT_API: z.string(),
    NEXT_PUBLIC_ENV: z.string(),
    NEXT_PUBLIC_API_KEY: z.string(),
    NEXT_PUBLIC_CENTRA_ENGRAVING_ID: z.string(),
    NEXT_PUBLIC_CENTRA_GIFT_CATEGORY_ID: z.string(),
    NEXT_PUBLIC_CENTRA_GIFT_COLLECTION_ID: z.string(),
    NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_ID: z.string(),
    NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_NAME: z.string(),
    NEXT_PUBLIC_CENTRA_ALL_PRODUCTS_MARKET: z.coerce.number().optional(),
    NEXT_PUBLIC_ALGOLIA_APP_ID: z.string(),
    NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN: z.string(),
    NEXT_PUBLIC_STREAM_URL: z.string().url(),
  },
  runtimeEnv: {
    FF_KEY: process.env.FF_KEY,
    NEXT_PREVIEW_TOKEN: process.env.NEXT_PREVIEW_TOKEN,
    VIMEO_API_KEY: process.env.VIMEO_API_KEY,
    CENTRA_API_SECRET: process.env.CENTRA_API_SECRET,
    CENTRA_INTEGRATION_TOKEN: process.env.CENTRA_INTEGRATION_TOKEN,
    CENTRA_INTEGRATION_URL: process.env.CENTRA_INTEGRATION_URL,
    NEXT_PUBLIC_CENTRA_ENGRAVING_ID:
      process.env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID,
      NEXT_PUBLIC_CENTRA_GIFT_CATEGORY_ID: 
        process.env.NEXT_PUBLIC_CENTRA_GIFT_CATEGORY_ID,
      NEXT_PUBLIC_CENTRA_GIFT_COLLECTION_ID:
        process.env.NEXT_PUBLIC_CENTRA_GIFT_COLLECTION_ID,
    NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_ID:
      process.env.NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_ID,
    NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_NAME:
      process.env.NEXT_PUBLIC_CENTRA_CAPS_CATEGORY_NAME,
    STORYBLOK_ACCESS_TOKEN: process.env.STORYBLOK_ACCESS_TOKEN,
    STORYBLOK_ACCESS_TOKEN_PREVIEW: process.env.STORYBLOK_ACCESS_TOKEN_PREVIEW,
    NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    NEXT_PUBLIC_CENTRA_CHECKOUT_API:
      process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API,
    NEXT_PUBLIC_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_API_KEY: process.env.NEXT_PUBLIC_API_KEY,
    CENTRA_DOMAIN: process.env.CENTRA_DOMAIN,
    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN:
      process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN,
    NEXT_PUBLIC_CENTRA_ALL_PRODUCTS_MARKET:
      process.env.NEXT_PUBLIC_CENTRA_ALL_PRODUCTS_MARKET,
    NEXT_PUBLIC_STREAM_URL: process.env.NEXT_PUBLIC_STREAM_URL
  },
});

export default env;
