import(/* webpackMode: "eager", webpackExports: ["SetCampaign"] */ "/vercel/path0/apps/varsity/src/app/[locale]/SetCampaign.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/_Link/_Link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/Accordion/AccordionGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/BlokWrap/BlokWrap.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Breadcrumbs/breadcrumbs.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/ButtonLink/ButtonLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/CartItem/CartItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Heading/heading.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/InnerHTML/ClientInnerHTML.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/Input/Input.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Link/link.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/NewsletterInput/NewsletterInput.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Price/Price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/ProductCard/LocalisedLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/ProductCard/product-card.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["SuspendedProductCardActions"] */ "/vercel/path0/apps/varsity/src/components/atoms/ProductCard/ProductCardActions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/QuantitySelector/QuantitySelector.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/Skeleton/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/atoms/RichText/rich-text.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/SearchBar/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/atoms/Video/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/modifiers/AspectRatioProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/components/molecules/PlpBanner/index.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["RecommendedProductCards"] */ "/vercel/path0/apps/varsity/src/components/molecules/Search/RecommendedProducts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/components/tools/Device/Device.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/atoms/MediaElement/sb-media.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/atoms/MediaElement/StoryblokImage/sb-image.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Content/content.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/atoms/ArticleCard/article-card.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/layout/ArticleCategory/article-category.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Body/body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Accordion/accordion.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ArticleSection/components/ArticleSlides.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ArticleSection/content.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Button/button.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ContentSection/components/ContentSlides.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ContentSection/content.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Form/index.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Hero/hero.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Media/Media.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/MediaGallery/media-gallery.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/NewsletterSignup/newslettersignup.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ProductListing/components/ProductSlides.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/ProductListing/product-listing.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Retailers/RetailersUI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/sections/Video/Media.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/statics/header/HeaderBloks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/bloks/statics/header/MegaHeaderBloks.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/lib/bloks/StoryblokComponent/ClientComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/varsity/src/lib/utils/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/varsity/src/styles/_index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.3.1/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.3.1/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.9.4_next@14.2.16_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./WorkSans-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./WorkSans-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-work-sans\"}],\"variableName\":\"WorkSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./BeausiteFit-LightItalic-Web.woff\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./BeausiteFitWeb-Light.woff\",\"weight\":\"300\",\"style\":\"normal\"}],\"variable\":\"--font-beausite\"}],\"variableName\":\"Beausite\"}");
